import React from 'react'
import CheriFont from 'url:../static/CheriFont.ttf'
import { Global, css } from '@emotion/react';

const GlobalStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'MyCustomFont';
        src: url(${CheriFont}) format('truetype');
        font-weight: normal;
        font-style: normal;
      }

      body {
        font-family: 'MyCustomFont', sans-serif;
      }
    `}
  />
);

export default GlobalStyles;